import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlobalTiles from "../../components/globalTiles"
import ImageTween from "../../components/imageTween"

const Covid = () => {
  const lang = "pl"

  return (
    <Layout lang={lang} translation="/covid-19/">
      <SEO title="Covid 19" />

      <section className="covid-top global__padd--bigger">
        <h1>Bądźmy gotowi na nową normalność - Droga powrotna do biur</h1>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="covid-top__inner">
              <p>
                Pandemia nie tylko zmieniła wzorce interakcji, ale także
                standardy sanitarne i higieniczne, wprowadziła pojęcie dystansu
                społecznego do codzienności i przyczyniła się do rozwoju
                komunikacji cyfrowej, a przede wszystkim wymusiła na nas zmiany
                w systemie pracy. Wraz z rozluźnieniem się obostrzeń rządowych,
                instytucje i firmy przygotowują się do nowej normalności.
                <br />
                <br />
                Projektując kompleks UNITY CENTRE podjęliśmy środki ostrożności,
                które mają za zadanie powstrzymywać rozprzestrzenianie się
                wirusa COVID-19. Oto jak ulepszamy architekturę naszych
                budynków, tak, aby służyła kwestiom bezpieczeństwa:
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <ImageTween
              src={require("../../assets/images/covid-content-image-2.jpg")}
              srcSet={require("../../assets/images/covid-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
        </div>
        <span className="global__line"></span>
      </section>

      <section className="covid-content global__padd--bigger">
        <div className="row">
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-1.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Zachowanie dystansu społecznego</h4>
                <p className="covid-touppercase">
                  Dla bezpieczeństwa wszystkich naszych najemców przygotowaliśmy
                  oznaczenia, które pomogą zachować bezpieczną 2-metrową
                  odległość od siebie podczas poruszania się po kompleksie.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-2.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4 >Zakaz zbierania się w większych grupach </h4>
                <p className="covid-touppercase">
                  Części wspólne zostały zaprojektowane tak, aby zapobiegać
                  gromadzeniu się w większych grupach. Odpowiednie
                  rozmieszczenie mebli i oznakowania pozwala rozproszyć grupy
                  osób przebywających w holach budynków lub na placu UNITY
                  Square.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-3.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Instrukcje i grafiki</h4>
                <p className="covid-touppercase">
                  Aby wesprzeć właściwą higienę rąk w naszym kompleksie znajdują
                  się wskazówki jak prawidłowo myć ręce przez min. 30 sekund. Są
                  one umieszczone we wszystkich toaletach. Humorystyczne plakaty
                  z pewnością umilą ten czas.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-4.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Korzystanie z wind przez max. 3 osoby</h4>
                <p className="covid-touppercase">
                  Przy zachowaniu bezpiecznej odległości wprowadzono
                  ograniczenia dotyczące liczby osób korzystających z wind.
                  Obecnie do każdej z nich mogą wejść maksymalnie 3 osoby.
                  Pozwala to na zachowanie dystansu i zwiększa poczucie
                  bezpieczeństwa nawet w zamkniętym i niezbyt dużym
                  pomieszczeniu.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-5.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Regularna dezynfekcja</h4>
                <p className="covid-touppercase">
                  Wszystkie najczęściej uczęszczane miejsca są na bieżąco
                  dezynfekowane przez serwis sprzątający. Regularnej dezynfekcji
                  poddawane są przede wszystkim klamki, uchwyty, poręcze,
                  przyciski oraz powierzchnie płaskie. Wszystkie używane
                  produkty są certyfikowane - niszczą wirusy, ale są bezpieczne
                  dla ludzi. We wszystkich miejscach o dużym natężeniu ruchu
                  używamy również jednorazowych ściereczek do dezynfekcji.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-6.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Środki do dezynfekcji rąk</h4>
                <p className="covid-touppercase">
                  Dostępne w częściach wspólnych dla wszystkich użytkowników.
                  Higiena rąk, to obok zakrycia twarzy, najważniejszy element
                  walki z wirusami. W częściach wspólnych dostępne są podajniki
                  z płynem do dezynfekcji, dzięki którym każdy użytkownik
                  kompleksu może skutecznie zdezynfekować ręce i czuć się
                  bezpiecznie podczas wizyty w UNITY CENTRE.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-7.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Obowiązek zakrywania twarzy w częściach wspólnych</h4>
                <p className="covid-touppercase">
                  W celu maksymalnego dbania o zdrowie naszych najemców,
                  obowiązkowe jest noszenie masek w częściach wspólnych
                  kompleksu tj. holach głównych, windach, czy garażach.
                  Prawidłowe noszenie maski zmniejsza szansę zarażenia się nie
                  tylko COVID-19, ale także innymi wirusami przenoszonymi drogą
                  kropelkową. Maski będą też dostępne w recepcji każdego z
                  budynków.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="covid-content__tile">
              <div className="covid-content__tile-image">
                <img
                  src={require("../../assets/images/covid-content-ico-8.svg")}
                  alt=""
                />
              </div>
              <div className="covid-content__tile-inner">
                <h4>Kurierzy i dostawcy</h4>
                <p className="covid-touppercase">
                  W trosce o komfort i bezpieczeństwo naszych najemców
                  postanowiliśmy uporządkować kwestie związane z dostawami
                  listów, paczek i żywności w naszym kompleksie. Wszyscy
                  kurierzy i dostawcy wpuszczani są tylko do recepcji danego
                  budynku i tam proszeni są o pozostawienie paczki lub
                  oczekiwanie na odbiór osobisty. Takie rozwiązanie pozwala
                  uniknąć przenoszenia wirusów do biurowca, które mogą znajdować
                  się również na ubraniach lub przedmiotach.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="covid-grid global__padd">
        <div className="row no-gutters align-items-center">
          <div className="col-md-8">
            <div className="covid-grid__inner">
              <h2>Plan działań na rzecz Twojego bezpiecznego biura</h2>
              <p>
                Jesteśmy po to, aby pomóc Ci wejść w nową normalność w miejscu
                pracy. Wspieramy firmy w przygotowaniu bezpiecznego powrotu do
                biur, projektując spersonalizowane rozwiązania dostosowane do
                indywidualnych potrzeb.
              </p>
              <Link
                to="/pl/kontakt/"
                className="btn-custom-back btn-custom-back--orange"
              >
                Skontaktuj się z nami
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <ImageTween
              src={require("../../assets/images/covid-content-image-1.jpg")}
              srcSet={require("../../assets/images/covid-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default Covid
